import { defineStore } from 'pinia'
import axios from 'axios'

interface SortFilter {
    field: string,
    direction: Number
}

interface User {
  username: string
  favorites: string[]
  isAdmin: boolean
  email: string
  dashboardFilters: {
    sort?: SortFilter
    submittedBy?: string
    favorites?: boolean
    searchString?: string
    scrollPosition: number
    tags?: string[]
  }
}

interface UserStore {
  user: User
  fetchUserData: () => Promise<void>
  setUsername: (username: string) => void
  updateDashboardFilter: (currentUsername: string, newUsername: string) => void
  addFavorite: (recipeUrl: string) => Promise<void>
  removeFavorite: (recipeUrl: string) => Promise<void>
  isFavoriteRecipe: (recipeUrl: string) => boolean
  setDashboardSort: (sort: SortFilter) => Promise<void>
  setDashboardSubmittedBy: (submitted: string) => Promise<void>
  setDashboardFavorites: () => Promise<void>
  resetDashboardFavorites: () => Promise<void>
  setDashboardSearch: (search: string) => Promise<void>
  addDashboardTag: (tag: string) => void
  removeDashboardTag: (tag: string) => void
  clear: () => void
}

export const useUserStore = defineStore({
  id: 'user',
  state: (): { user: User } => ({
    user: {
      username: '',
      favorites: [],
      isAdmin: false,
      email: '',
      dashboardFilters: {
        scrollPosition: 0,
        sort: { // Default sort is by date descending
          field: 'createdAt',
          direction: -1
        },
        tags: []
      }
    }
  }),
  actions: {
    // fetch user data from API on initial application load
    async fetchUserData(this: UserStore): Promise<void> {
      const { data } = await axios.get('/api/user')
      this.user = data
      this.user.dashboardFilters.scrollPosition = 0;
    },

    // set username when changed through the account menu
    setUsername(this: UserStore, username: string): void {
      this.user.username = username
    },

    // update username when changed through the account menu
    // if username is set as a dashboard filter
    updateDashboardFilter(this: UserStore, currentUsername: string, newUsername: string): void {
      const { dashboardFilters } = this.user
      if (dashboardFilters.submittedBy === currentUsername) {
        dashboardFilters.submittedBy = newUsername
      }
    },
    
    // manage favorites on recipe page
    async addFavorite(this: UserStore, recipeUrl: string): Promise<void> {
      const { favorites } = this.user
      await axios.post(`/api/user/favorites/${recipeUrl}`)
      favorites.push(recipeUrl) // Add favorite to local user favorites
    },

    // manage favorites on recipe page
    async removeFavorite(this: UserStore, recipeUrl: string): Promise<void> {
      await axios.delete(`/api/user/favorites/${recipeUrl}`)
      const index = this.user.favorites.indexOf(recipeUrl)
      if (index !== -1) {
        this.user.favorites.splice(index, 1) // Remove favorite from local user favorites
      }
    },

    // check if recipe is a favorite, used on the recipe page
    isFavoriteRecipe(this: UserStore, recipeUrl: string): boolean {
      return this.user.favorites.includes(recipeUrl)
    },

    async setDashboardSort(this: UserStore, sortFilter: SortFilter): Promise<void> {
      await axios.post('/api/user/dashboard/sort', { sort: sortFilter })
      this.user.dashboardFilters.sort = sortFilter
    },

    async setDashboardSubmittedBy(this: UserStore, submitted: string): Promise<void> {
      await axios.post('/api/user/dashboard/submittedBy', { submittedBy: submitted })
      this.user.dashboardFilters.submittedBy = submitted
    },

    toggleDashboardFavorites(this: UserStore): void {
      this.user.dashboardFilters.favorites = !this.user.dashboardFilters.favorites
      this.setDashboardFavorites()
    },

    resetDashboardFavorites(this: UserStore): void {
      this.user.dashboardFilters.favorites = false
      this.setDashboardFavorites()
    },

    async setDashboardFavorites(this: UserStore): Promise<void> {
      try {
        await axios.post('/api/user/dashboard/favorites', { favorites: this.user.dashboardFilters.favorites })
      } catch (error) {
        // TODO: Improve error handling by notifying user
        console.error(error)
      }
    },

    // TODO: See if this can be called using a watch in the store, instead of calling it from the dashboard component
    async setDashboardSearch(this: UserStore): Promise<void> {
      await axios.post('/api/user/dashboard/searchString', { searchString: this.user.dashboardFilters.searchString  })
    },

    setDashboardScrollPosition(this: UserStore, scrollPosition: number): void {
      this.user.dashboardFilters.scrollPosition = scrollPosition
    },

    getDashboardScrollPosition(this: UserStore): number {
      return this.user.dashboardFilters.scrollPosition
    },

    async addDashboardTag(this: UserStore, tag: string): Promise<void> {
      await axios.post('/api/tags/add', {tag: tag})
      this.user.dashboardFilters.tags?.push(tag)
    },
    
    async removeDashboardTag(this: UserStore, tag: string): Promise<void> {
      await axios.delete('/api/tags/remove', {data: {tag: tag}}) 
      const index = this.user.dashboardFilters.tags?.indexOf(tag)
      if (index !== undefined && index !== -1) {
        this.user.dashboardFilters.tags?.splice(index, 1)
      }
    },

    toggleDashboardTag(this: UserStore, tag: string): void {
      if (this.user.dashboardFilters.tags?.includes(tag)) {
        this.removeDashboardTag(tag)
      } else {
        this.addDashboardTag(tag)
      }
    },

    async resetDashboardTags(this: UserStore): Promise<void> {
      await axios.delete('/api/tags')
      this.user.dashboardFilters.tags = []
    },
    clear(): void {
      this.user = {
        username: '',
        favorites: [],
        isAdmin: false,
        email: '',
        dashboardFilters: {
          scrollPosition: 0,
          sort: {
            field: 'createdAt',
            direction: -1
          },
          tags: []
        }
      }
    }
  }
})
