import { defineStore } from 'pinia'

// Define and export a Pinia store
export const useNotificationStore = defineStore({
  // Unique id of the store
  id: 'notifications',

  // State of the store
  state: () => ({
    // An array to hold all notifications
    notifications: []
  }),

  // Actions of the store
  actions: {
    // Method to add a notification with a success status
    success(message, autoCloseTime = 5000) {
      this.addNotification({ type: 'success', message, autoCloseTime })
    },

    // Method to add a notification with an error status
    error(message, autoCloseTime = 5000) {
      this.addNotification({ type: 'error', message, autoCloseTime })
      console.error(message) // Log the error to the console, should get picked up by Sentry
    },

    // Method to add a notification with a general status
    general(message, autoCloseTime = 5000) {
      this.addNotification({ type: 'primary', message, autoCloseTime })
    },

    // Method to add a notification to the array
    addNotification(notification) {
      // Push the new notification to the array
      this.notifications.push(notification)

      // Set a timeout to automatically remove the notification after a specified time
      // If no time is specified, default to 5 seconds
      setTimeout(() => {
        this.removeNotification(notification)
      }, notification.autoCloseTime || 5000)
    },

    // Method to remove a notification from the array
    removeNotification(notification) {
      // Find the index of the notification in the array
      const index = this.notifications.indexOf(notification)

      // If the notification is found, remove it from the array
      if (index !== -1) {
        this.notifications.splice(index, 1)
      }
    }
  }
})
