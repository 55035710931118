// Import bootstrap in cookbook client project
// This is nice because bootstrap updates can be done using NPM
// This also allows importing and referencing bootstrap items programatically in Vue components
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

import * as Sentry from '@sentry/vue'

import axios from 'axios'

const app = createApp(App)

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  enabled: import.meta.env.VITE_SENTRY_ENABLED === 'true', // Ensure boolean conversion
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  release: import.meta.env.VITE_SENTRY_RELEASE,
  integrations: [Sentry.browserTracingIntegration({ router })],

  // Set tracesSampleRate to 1.0 to capture 100% of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: parseFloat(import.meta.env.VITE_SENTRY_TRACE_SAMPLE_RATE),

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: import.meta.env.VITE_SENTRY_TRACE_PROPAGATION_TARGETS.split(',')

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  // replaysSessionSampleRate: parseFloat(import.meta.env.VITE_SENTRY_REPLAY_SESSION_SAMPLE_RATE),
  // replaysOnErrorSampleRate: parseFloat(import.meta.env.VITE_SENTRY_REPLAY_ERROR_SESSION_SAMPLE_RATE)
})

// Add an allow list for public pages (e.g. register, recovery, etc.)
const publicPages = ['/register', '/recover', '/reset', '/passcode', '/status']

axios.interceptors.response.use(
  (response) => response, // Simply return the response if it's successful
  (error) => {
    if (error.response.status === 401) {
      // Get the current url
      let currentRoute = window.location.href

      // Get path from url, which is after the domain / origin
      currentRoute = currentRoute.replace(window.location.origin, '')

      // Check if the current route starts with a path in the public pages list
      const isPublicPage = publicPages.some((path) => currentRoute.startsWith(path))

      // Redirect to login if the response status is 401 and the current route is not a public page
      if (!isPublicPage) {
        router.push('/login')
      }
    }
    return Promise.reject(error)
  }
)

app.use(createPinia())
app.use(router)

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* Disable auto-adding CSS - handling this ourselves lets us maintain a strict CSP policy */
import { config } from '@fortawesome/fontawesome-svg-core'
config.autoAddCss = false

/* import specific icons */
import {
  faBars,
  faSearch,
  faXmark,
  faUserCircle,
  faSquareXmark,
  faHome,
  faUserPen,
  faUsersCog,
  faTag,
  faInfoCircle,
  faSignOutAlt,
  faPencilAlt,
  faStar as fasStar,
  faUndo,
  faTags,
  faSortAmountDown,
  faUser,
  faGlobe,
  faEraser,
  faPlus,
  faArrowsUpDown,
  faSquareCaretUp,
  faSquareCaretDown,
  faTrashCan,
  faCamera,
  faGear,
  faArrowUpRightFromSquare,
  faShareFromSquare
} from '@fortawesome/free-solid-svg-icons'
import {
  faLightbulb,
  faStar as farStar,
  faRectangleList,
  faSquare,
  faSquareCheck,
  faImage,
  faCommentDots,
  faStickyNote
} from '@fortawesome/free-regular-svg-icons'

/* add icons to the library */
library.add(
  faBars,
  faSearch,
  faXmark,
  faLightbulb,
  faUserCircle,
  faSquareXmark,
  faHome,
  faUserPen,
  faUsersCog,
  faTag,
  faInfoCircle,
  faSignOutAlt,
  faPencilAlt,
  fasStar,
  farStar,
  faUndo,
  faTags,
  faSortAmountDown,
  faUser,
  faGlobe,
  faEraser,
  faPlus,
  faRectangleList,
  faArrowsUpDown,
  faSquareCaretUp,
  faSquareCaretDown,
  faSquare,
  faSquareCheck,
  faTrashCan,
  faCamera,
  faImage,
  faCommentDots,
  faStickyNote,
  faGear,
  faArrowUpRightFromSquare,
  faShareFromSquare
)

/* Import FontAwesome CSS manually */
import '@fortawesome/fontawesome-svg-core/styles.css'

// Mount the font awesome component globally
app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app')
