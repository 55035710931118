import { defineStore } from 'pinia'
import axios from 'axios'

interface SubmittedBy {
  submittedBy: string[]
}

interface SubmittedByStore {
  submittedBy: SubmittedBy
  fetchSubmittedByData: () => Promise<void>;
  updateUsername: (currentUsername: string, newUsername: string) => void;
}

export const useSubmittedByStore = defineStore({
  id: 'submitted-by',
  state: () => ({
    submittedBy: []
  }),
  actions: {
    // fetch submitted by data from API on application load
    async fetchSubmittedByData(this: SubmittedByStore): Promise<void> {
      try {
        const { data } = await axios.get('/api/dashboard/submittedby')
        this.submittedBy = data
      } catch (error) {
        // TODO: use notification library to display this to user
        console.error(error)
      }
    },
    updateUsername(currentUsername: string, newUsername: string): void {
      this.submittedBy = this.submittedBy.map(username => username === currentUsername ? newUsername : username);
    },
    newRecipeSubmitted(username: string): void {
      // When a recipe is submitted, add new username to submittedBy array if it doesn't already exist
      if (!this.submittedBy.includes(username)) {
        this.submittedBy.push(username)
        this.submittedBy.sort()
      }
    },
    clear(): void {
      this.submittedBy = []
    }
  }
})