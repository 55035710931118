<template>
  <div class="toast-container bottom-0 start-50 translate-middle-x p-3">
    <div
      v-for="notification in notificationStore.notifications"
      :key="notification.id"
      class="toast show text-white"
      :class="toastClass(notification.type)"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      @click="closeToast"
    >
      <div class="toast-body" data-bs-dismiss="toast">
        {{ notification.message }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { useNotificationStore } from '@/stores/NotificationStore'
const notificationStore = useNotificationStore()

const toastClass = (type) => {
  switch (type) {
    case 'success':
      return 'bg-success'
    case 'error':
      return 'bg-danger'
    default:
      return 'bg-primary'
  }
}
</script>
