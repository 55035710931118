import { createRouter, createWebHistory } from 'vue-router'
import { getCookie } from '@/utils/cookieUtils'
import { COOKIE_NAMES } from '@/utils/constants'
import PageLogin from '@/components/PageLogin/PageLogin.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Dashboard',
      component: () => import('@/components/PageDashboard/PageDashboard.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/recipe-editor/:id',
      name: 'RecipeEditor',
      component: () => import('@/components/PageRecipeEditor/PageRecipeEditor.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/recipe/:id',
      name: 'Recipe',
      component: () => import('@/components/PageRecipe/PageRecipe.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/login',
      name: 'Login',
      component: PageLogin
    },
    {
      path: '/recover',
      name: 'RecoverRequest',
      component: () => import('@/components/PageRecover/PageRecoverRequest.vue')
    },
    {
      path: '/recover/:token',
      name: 'RecoverSubmit',
      component: () => import('@/components/PageRecover/PageRecoverSubmit.vue')
    },
    {
      path: '/passcode',
      name: 'Passcode',
      component: () => import('@/components/PageRegister/PageRegisterPasscode.vue')
    },
    {
      path: '/register',
      name: 'Register',
      component: () => import('@/components/PageRegister/PageRegister.vue')
    },
    {
      path: '/status',
      name: 'Status',
      component: () => import('@/components/PageStatus/PageStatus.vue')
    }
  ]
})

// Route guard implementation
router.beforeEach((to, from, next) => {
  const routeGuardCookie = getCookie(COOKIE_NAMES.ROUTE_GUARD)
  let isLoggedIn = routeGuardCookie === 'true' // Convert string to boolean

  if (to.meta.requiresAuth && !isLoggedIn) {
    // Redirect to login page if user is not authenticated
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router
